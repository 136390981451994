<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">
        <router-link to="/" class="go-back">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z"
              fill="#0033A0"
            />
          </svg>
          на интерактивную карту
        </router-link>

        <h1>
          <div>
            <img
              :src="require('@/assets/reglogos/' + region.code + '.png')"
              alt=""
              style="max-width: 40px; max-height: 50px"
            />{{ region.name }}
          </div>
          <div v-if="false">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <a href="#" v-bind="attrs" v-on="on">
                  <v-btn>Редактировать</v-btn>
                </a>
              </template>
              <!-- <RegionEditDialog @close="dialog = false" /> -->
            </v-dialog>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="round-btn" v-bind="attrs" v-on="on">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 14V12H13V14H5ZM13 7L9 11L5 7H7.5V4H10.5V7H13ZM2 0H16C17.11 0 18 0.9 18 2V16C18 17.11 17.11 18 16 18H2C0.9 18 0 17.11 0 16V2C0 0.9 0.9 0 2 0ZM2 2V16H16V2H2Z"
                      fill="#0033A0"
                    />
                  </svg>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>Скачать PDF</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Скачать XLS</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </h1>

        <div class="badges">
          <div>
            Участие в Федеральном Проекте <span>{{ region.year }}</span>
          </div>
          <div>Центров занятости, участвующих в ФП <span>0</span></div>
          <div>
            Подключен к ЕЦП <span>{{ region.connectedDate | dateFormat }}</span>
          </div>
        </div>

        <div class="table-wrap">
          <table class="info-table top-table">
            <tbody>
              <tr>
                <td>
                  Общий объем полученных средств федерального бюджета в рамках
                  ФП
                </td>
                <td>{{ region.fedBudget | moneyFormat }}</td>
              </tr>
              <tr>
                <td>
                  Общий объем средств регионального бюджета на мероприятия по
                  модернизации
                </td>
                <td>{{ region.regBudget | moneyFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>Ответственный от региона за ФП</h2>

        <div class="person">
          <div><img src="~@/assets/person.png" alt="" /></div>
          <div>
            <p>{{ region.inCharge ? region.inCharge : "&mdash;" }}</p>
            <span>{{
              region.inChargPost ? region.inChargPost : "&mdash;"
            }}</span>
            <div>
              <a
                v-if="region.inChargePhone != null"
                :href="'tel:' + region.inChargePhone"
                >{{ region.inChargePhone }}</a
              >
              <span v-else>&mdash;</span>
              |
              <a
                v-if="region.inChargeEmail != null"
                :href="'mailto:' + region.inChargeEmail"
                >{{ region.inChargeEmail }}</a
              >
              <span v-else>&mdash;</span>
            </div>
          </div>
        </div>

        <h2>
          <div>Список ЦЗН</div>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            v-if="$authorized"
          >
            <template v-slot:activator="{ on, attrs }">
              <a href="#" v-bind="attrs" v-on="on" @click.prevent="">
                <v-btn>Добавить ЦЗН</v-btn>
              </a>
            </template>
            <CznEditDialog @close="dialog = false" :region="region" />
          </v-dialog>
        </h2>

        <div class="gmap">
          <div class="map-left">
            <!--<GmapMap-->
                <!--ref="mapRef"-->
                <!--:center="mapCenter"-->
                <!--:zoom="7"-->
                <!--:options="{-->
                   <!--zoomControl: true,-->
                   <!--mapTypeControl: false,-->
                   <!--scaleControl: false,-->
                   <!--streetViewControl: false,-->
                   <!--rotateControl: false,-->
                   <!--fullscreenControl: true,-->
                   <!--disableDefaultUI: false-->
                 <!--}"-->
                <!--map-type-id="terrain"-->
                <!--style="width: 820px; height: 460px"-->
            <!--&gt;-->
              <!--<GmapMarker-->
                  <!--:key="index"-->
                  <!--v-for="(m, index) in markers"-->
                  <!--:position="m.position"-->
                  <!--:clickable="true"-->
                  <!--:draggable="true"-->
                  <!--@click="$router.push('/czn/'+m.cznId)"-->
              <!--/>-->
            <!--</GmapMap>-->
              <yandex-map
                      :coords="mapCenter"
                      :zoom="10"
                      :show-all-markers="markers.length > 1 ? true : false"
              >
                  <ymap-marker
                          v-for="(m, index) in markers"
                          :key="index"
                          :coords="m"
                          marker-id="index"
                          hint-content=""
                  />
              </yandex-map>
          </div>

          <div class="map-right">
            <v-text-field
              placeholder="Введите название ЦЗН"
              v-model="cznFilter"
              @input="updateCznsList()"
              filled
              dense
              rounded
            ></v-text-field>
            <ul>
              <template v-for="czn in czns">
                <li :key="czn.id + 'sdfer'" v-if="filterCzns(czn.name)" @input="$forceUpdate()">
                  <router-link :to="'/czn/'+czn.id">
                    {{ czn.name }}</router-link
                  > <span v-if="czn.fedProjectYear != null">ФП</span>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <h2>Информация</h2>

        <div class="table-wrap">
          <table class="info-table">
            <tbody>
              <tr>
                <td>
                  РОИВ, осуществляющий полномочия в области содействия занятости
                  населения
                </td>
                <td>{{ region.roiv ? region.roiv : "&mdash;" }}</td>
              </tr>
              <tr>
                <td>Руководитель службы занятости населения субъекта РФ</td>
                <td>
                  <div class="person-short">
                    <p>
                      {{ region.roivChief ? region.roivroivChief : "&mdash;" }}
                    </p>
                    <span>{{
                      region.roivPost ? region.roivPost : "&mdash;"
                    }}</span>
                    <div>
                      <a
                        v-if="region.roivPhone != null"
                        :href="'tel:' + region.roivPhone"
                        >{{ region.roivPhone }}</a
                      >
                      <span v-else>&mdash;</span>
                      |
                      <a
                        v-if="region.roivEmail != null"
                        :href="'mailto:' + region.roivEmail"
                        >{{ region.roivEmail }}</a
                      >
                      <span v-else>&mdash;</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Организационная форма сети ЦЗН</td>
                <td>{{ region.netOPF ? region.netOPF : "&mdash;" }}</td>
              </tr>
              <tr>
                <td>Число ЦЗН субъекта РФ</td>
                <td>{{ region.cznCount ? region.cznCount : "&mdash;" }}</td>
              </tr>
              <tr>
                <td>Число точек приема</td>
                <td>
                  {{ region.officeCount ? region.officeCount : "&mdash;" }}
                </td>
              </tr>
              <tr>
                <td>Организационно-правовая форма ЦЗН субъекта РФ</td>
                <td>{{ region.cznOPF ? region.cznOPF : "&mdash;" }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>Показатели</h2>

        <div class="table-wrap">
          <table
            width="100%"
            border="0"
            cellspacing="0"
            cellpadding="0"
            class="info-table"
          >
            <tbody>
              <tr>
                <th scope="col"></th>
                <th scope="col">2018</th>
                <th scope="col">2019</th>
                <th scope="col">2020</th>
                <th scope="col">2021</th>
              </tr>
              <tr>
                <td>Общая фактическая численность персонала ЦЗН</td>
                <td>
                  {{ region.allStaff2018 ? region.allStaff2018 : "&mdash;" }}
                </td>
                <td>
                  {{ region.allStaff2019 ? region.allStaff2019 : "&mdash;" }}
                </td>
                <td>
                  {{ region.allStaff2020 ? region.allStaff2020 : "&mdash;" }}
                </td>
                <td>
                  {{ region.allStaff2021 ? region.allStaff2021 : "&mdash;" }}
                </td>
              </tr>
              <tr>
                <td>Общий ФОТ сотрудников ЦЗН</td>
                <td>{{ region.allFot2018 | moneyFormat }}</td>
                <td>{{ region.allFot2019 | moneyFormat }}</td>
                <td>{{ region.allFot2020 | moneyFormat }}</td>
                <td>{{ region.allFot2021 | moneyFormat }}</td>
              </tr>
              <tr>
                <td>Средняя заработная плата сотрудников ЦЗН</td>
                <td>{{ region.avgCznSalary2018 | moneyFormat }}</td>
                <td>{{ region.avgCznSalary2019 | moneyFormat }}</td>
                <td>{{ region.avgCznSalary2020 | moneyFormat }}</td>
                <td>{{ region.avgCznSalary2021 | moneyFormat }}</td>
              </tr>
              <tr>
                <td>
                  Отношение средней заработной платы сотрудников ЦЗН к средней
                  заработной плате по региону
                </td>
                <td>{{ region.avgSalaryRatio2018 | ratioFormat }}</td>
                <td>{{ region.avgSalaryRatio2019 | ratioFormat }}</td>
                <td>{{ region.avgSalaryRatio2020 | ratioFormat }}</td>
                <td>{{ region.avgSalaryRatio2021 | ratioFormat }}</td>
              </tr>
              <tr>
                <td>
                  Число граждан, обратившихся в целях поиска подходящей работы в
                  ЦЗН
                </td>
                <td>
                  {{
                    region.clientCount2018 ? region.clientCount2018 : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.clientCount2019 ? region.clientCount2019 : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.clientCount2020 ? region.clientCount2020 : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.clientCount2021 ? region.clientCount2021 : "&mdash;"
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  Доля трудоустроенных граждан в численности граждан,
                  обратившихся в целях поиска подходящей работы в ЦЗН
                </td>
                <td>
                  {{
                    region.employedRatio2018
                      ? region.employedRatio2018
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.employedRatio2019
                      ? region.employedRatio2019
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.employedRatio2020
                      ? region.employedRatio2020
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.employedRatio2021
                      ? region.employedRatio2021
                      : "&mdash;"
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  Среднее время на трудоустройство через ЦЗН (с момента подачи
                  заявления до момента трудоустройства)
                </td>
                <td>
                  {{
                    region.avgEmployTime2018
                      ? region.avgEmployTime2018
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.avgEmployTime2019
                      ? region.avgEmployTime2019
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.avgEmployTime2020
                      ? region.avgEmployTime2020
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.avgEmployTime2021
                      ? region.avgEmployTime2021
                      : "&mdash;"
                  }}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  Количество работодателей, обращавшихся в ЦЗН, за содействием в
                  подборе необходимых работников
                </td>
                <td>
                  {{
                    region.employersCount2018
                      ? region.employersCount2018
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.employersCount2019
                      ? region.employersCount2019
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.employersCount2020
                      ? region.employersCount2020
                      : "&mdash;"
                  }}
                </td>
                <td>
                  {{
                    region.employersCount2021
                      ? region.employersCount2021
                      : "&mdash;"
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  Доля работодателей, обратившихся за содействием в подборе
                  необходимых работников в ЦЗН в общем количестве организаций
                </td>
                <td>{{ region.employersRatio2018 | ratioFormat }}</td>
                <td>{{ region.employersRatio2019 | ratioFormat }}</td>
                <td>{{ region.employersRatio2020 | ratioFormat }}</td>
                <td>{{ region.employersRatio2021 | ratioFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import api from "../modules/api"

import CznEditDialog from "../components/dialogs/CznEditDialog"

export default {
  name: "Region",
  props: {},
  components: {
      CznEditDialog,
  },
  data() {
    return {
      dialog: false,
      isActive: false,
      region: null,
      czns: [],
      markers: [],
      mapCenter: [55.738778, 37.629861],
      cznFilter: '',
      apikey: '2c72f1a3-4eb2-42f7-b8be-5ddb2ef89a81'
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return "—";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    },
    moneyFormat: function (price) {
      if (price == null || price.length === 0) return "—";
      let s_price = new String(price);
      let ind = s_price.indexOf(',')
      if(ind > 0)
        s_price = s_price.substr(0, s_price.indexOf(','))
      let result = " ₽";
      let k = 0;
      for (let i = s_price.length - 1; i >= 0; i--) {
        if (k == 3) {
          k = 0;
          result = " " + result;
        }
        result = s_price[i] + result;
        k++;
      }
      return result;
    },
    ratioFormat: function (v) {
      if(v == null)
      return "—"
      let vv = +v.replace(",", ".")
      if(vv <= 1 && vv > 0){
        return (vv*100).toFixed(1) + "%"
      } else {
        return vv.toFixed(1) + "%"
      }
    },
  },
  methods: {
    async loadCzns() {
      let req = await api.get("/map/getCznsByRegCode", {
        regCode: this.regCode,
      });
      if (req.ok) {
        this.czns = req.payload;
      } else alert("Ошибка загрузки данных");
    },

    filterCzns(name) {
      return name.toUpperCase().includes(this.cznFilter.toUpperCase())
    },

    updateCznsList() {
      this.$forceUpdate()
    },

    async getCoords(name) {
        if (!name) {
            return false
        }
        const search = encodeURIComponent(name).replace(/%20/g, '+')
        const url = '//search-maps.yandex.ru/v1/?apikey=' + this.apikey + '&text=' + search + '&type=biz&lang=ru_RU&results=1'
        const addr = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Origin': window.location.origin,
                'Access-Control-Allow-Origin': '*',
            }
        })
        if (!addr.ok) {
            return false
        }
        const data = await addr.json()
        const coordinates = data?.features[0]?.geometry?.coordinates
        if (!coordinates) {
            return false
        }
        return [coordinates[1], coordinates[0]]
    },

  },
  beforeMount() {
    this.regCode = this.$route.params.code
    for (let r of this.regions) {
      if (r.code === this.regCode) {
          this.region = r
      }
    }
    this.loadCznsPromise = this.loadCzns()
  },
  mounted: async function() {
    await this.loadCznsPromise
    if(this.czns.length === 0) {
        console.log('region', this.region.name)
        this.mapCenter = await this.getCoords(this.region.name)
    } else {
      for(let c of this.czns) {
          this.markers.push(await this.getCoords(c.address))
      }
      this.mapCenter = this.markers[0]
    }
  },
}
</script>


<style lang="scss">
@import "../styles/main.scss";
</style>
